<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Soubory - seznam</strong>
      </div>
      <action-tools :router-options="routerOptions" :handle-refresh="handleRefresh"></action-tools>
    </div>
    <div class="card-body">
      <div class="row mb-3">
        <div class="col-md-6">
          <a-dropdown :placement="'bottomLeft'">
            <a-button>Hromadné akce
              <a-icon type="down"/>
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item :disabled="selectedRowKeys.length === 0" :class="{'disabled': selectedRowKeys.length === 0}">
                <a-popconfirm @confirm="confirmDelete(selectedRowKeys)" okText="Potvrdit" cancelText="Zrušit">
                  <template slot="title">
                    <p>Opravdu si přejete odstranit tyto položky?</p>
                    <div v-if="selectedRowKeys.length > 0">
                      {{ selectedRowKeys }}
                    </div>
                  </template>
                  Odstranit
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="text-right col-md-6">
          <a-input-search
            placeholder="Hledat..."
            style="width: 200px"
            @search="handleSearch"
          />
        </div>
      </div>
      <a-table
        tableLayout="auto"
        :rowSelection="rowSelection"
        :rowKey="record => record.fie_id"
        :columns="columns"
        :loading="loading"
        :dataSource="files"
        class="utils__scrollTable"
        :scroll="{ x: '100%' }"
      >
        <router-link
          slot="fie_id"
          slot-scope="value"
          class="utils__link--underlined"
          :to="'/file/detail/'+value"
        >#<span v-if="searchText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value}}</template>
        </router-link>
        <template slot="customRender" slot-scope="text">
          <a-alert v-if="text === undefined" message="není nastaveno" type="warning" showIcon />
          <span v-else-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </template>
        <span slot="products_count" slot-scope="text">
          <i class="fas fa-sitemap"></i>&nbsp;&nbsp;
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{text}}</template>
        </span>
        <span slot="action" slot-scope="record">
          <router-link :to="'/file/detail/'+record.fie_id">
            <a-button icon="search" size="small" class="mr-1">Zobrazit</a-button>
          </router-link>
          <a :href="$uploadFilePath + record.fie_path + '.' + record.fie_format" target='_blank'>
            <a-button icon="download" size="small" class="mr-1">Stáhnout</a-button>
          </a>
          <a-popconfirm @confirm="confirmDelete([record.fie_id])" okText="Potvrdit" cancelText="Zrušit">
            <template slot="title">
                <p>Opravdu si přejete odstranit položku: #{{ record.fie_id }} ?</p>
            </template>
            <a-button icon="delete" size="small">Odstranit</a-button>
          </a-popconfirm>
        </span>
        <template slot="footer">
          {{ files.length }} položek
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'

export default {
  components: { ActionTools },
  data: function () {
    return {
      file: {
        errors: [],
      },
      searchText: '',
      loading: false,
      selectedRowKeys: [],
      routerOptions: [
        {
          icon: 'plus-circle',
          to: '/file/add',
          title: 'Přidat',
        },
      ],
      columns: [
        {
          title: 'ID',
          dataIndex: 'fie_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.fie_id - b.fie_id,
          scopedSlots: {
            customRender: 'fie_id',
          },
        },
        {
          title: 'Název',
          dataIndex: 'fie_name',
          sorter: (a, b) => {
            return a.fie_name.localeCompare(b.fie_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Formát',
          dataIndex: 'fie_format',
          sorter: (a, b) => {
            return a.fie_format.localeCompare(b.fie_format)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Kategorie',
          dataIndex: 'file_category.fcy_name',
          sorter: (a, b) => {
            return a.file_category.fcy_name.localeCompare(b.file_category.fcy_name)
          },
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Produktů',
          dataIndex: 'products_count',
          sorter: (a, b) => a.products_count - b.products_count,
          scopedSlots: {
            customRender: 'products_count',
          },
        },
        {
          title: 'Akce',
          key: 'action',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
    }
  },
  computed: {
    files: function () {
      return this.$store.getters['file/getListFilteredItems'](this.searchText)
    },
    rowSelection() {
      const { selectedRowKeys } = this
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
        hideDefaultSelections: true,
        selections: [{
          key: 'all-data',
          text: 'Vybrat všechny položky',
          onSelect: () => {
            this.selectedRowKeys = this.selectedRowKeys.length === 0 ? this.files.map(item => item.fie_id) : []
          },
        }],
        onSelection: this.onSelection,
      }
    },
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleRefresh() {
      this.loading = true
      this.$store.dispatch('file/getList')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    confirmDelete(files) {
      this.loading = true
      this.$store.dispatch('file/delete', files)
        .then(() => {
          if (this.selectedRowKeys.length > 0) {
            this.selectedRowKeys = files.filter(x => !this.selectedRowKeys.includes(x))
              .concat(this.selectedRowKeys.filter(x => !files.includes(x)))
          }
        }).catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    handleSearch(searchText) {
      this.searchText = searchText
    },
  },
  created() {
    if (this.files.length === 0) {
      this.handleRefresh()
    }
  },
}
</script>

<style lang="scss" module scoped>
  @import "./style.module.scss";
</style>
